import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { Container as ContainerBase, Content2Xl } from "../../misc/Layouts";
import { SectionHeading } from "../../misc/Headings.js";
import { SectionDescription } from "../../misc/Typography.js";
import ServicesImageHero from "images/TP-team-home-hero.jpg";
import CustomersLogoStripImage from "../../images/partner-logos.png";

const PrimaryBackgroundContainer = tw.div`px-8 bg-primary-500 text-gray-100`;


const Container = tw(ContainerBase)`pt-24`;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`my-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(SectionDescription)`mt-4 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0`;
const Image = tw.img`w-144 ml-auto`

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`-mt-8 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({
  heading = "Providing a Range of IT B2B Demand & Lead Generation Services",
  description = "Supporting your Start-Up, Sales Pipeline, Customer Retention and Channel Development strategies, plus Bespoke Services",
  imageSrc = ServicesImageHero,
}) => {

  return (
    <>
    <PrimaryBackgroundContainer>
      <Content2Xl>
        <Container>
            <Row>
              <TextColumn>
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                <CustomersLogoStrip>
                  <p>Our TRUSTED partners</p>
                  <img src={CustomersLogoStripImage} alt="Our Customers" />
                </CustomersLogoStrip>
              </TextColumn>
              <IllustrationColumn>
                <Image src={imageSrc} />
              </IllustrationColumn>
            </Row>
        </Container>
      </Content2Xl>
    </PrimaryBackgroundContainer>
    </>
  );
};
