import React from "react";
import Footer from "helpers/Footer";
import CaseStudiesCristieHero from "./case-studies/CaseStudiesComponents/CaseStudiesCristieHero";
import CaseStudiesCristieDetails from "./case-studies/CaseStudiesComponents/CaseStudiesCristieDetails";
import CaseStudiesCristieInfo from "./case-studies/CaseStudiesComponents/CaseStudiesCristieInfo";
import CaseStudiesCristiePartner from "./case-studies/CaseStudiesComponents/CaseStudiesCristiePartner";



export default() => {
    return(
        <>
            <CaseStudiesCristieHero />
            <CaseStudiesCristieDetails />
            <CaseStudiesCristieInfo />
            <CaseStudiesCristiePartner />
            <Footer />
        </>
    )
}