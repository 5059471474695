import Footer from "helpers/Footer";
import React from "react";
import ServicesDataServicesHero from "./services/ServicesComponents/ServicesDataServicesHero";
import ServicesDataServicesInfo from "./services/ServicesComponents/ServicesDataServicesInfo";
import ServicesLeadGenerationSwiper from "./services/ServicesComponents/ServicesLeadGenerationSwiper";


export default() => {
    return(
        <>
            <ServicesDataServicesHero />
            <ServicesLeadGenerationSwiper />
            <ServicesDataServicesInfo />
            <Footer />
        </>
    )
}