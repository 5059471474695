import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ContentWithPaddingXl } from "../../misc/Layouts";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "../../misc/Headings";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Container = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch bg-primary-500 text-gray-100`;
const Vision = tw(SubheadingBase)`lg:px-40 py-8 text-gray-100`;
const Values = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch rounded-xl`;
const ValueContainer = tw.div`lg:my-16 lg:w-1/3`;
const Value = tw.div`p-8 text-center max-w-xs mx-auto flex flex-col items-center rounded-lg shadow-2xl bg-primary-600 h-80 w-80`;
const TitleText = tw.blockquote`mt-5 text-gray-100 font-bold text-xl leading-loose`;
const Text = tw.blockquote`mt-5 text-gray-100 font-medium leading-loose`;
const SlantedBackground = tw.div`text-gray-100`


const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  heading = "Our Vision",
  subheading = "To exceed our customers’ expectations by delivering the highest-level return on investment. We accomplish this by working as one team with our customers and applying our Company’s core values – dedication, honesty and integrity – across everything we do.",
  values = [
    {
      visionTitle: "Dedication",
      text:
        "We are highly committed to making your business more successful We are forward thinking and open minded, allowing us to adapt to your needs.",
      id: "1"
      
    },
    {
      visionTitle: "Honesty",
      text:
        "We commit to an honest and transparent working relationship. Your leads are reviewed and approved by one of our management team before they are given to you.",
      id: "2"
     
    },
    {
      visionTitle: "Integrity",
      text:
        "All data is GDPR compliant and securely stored. We treat our employees and customers with dignity and respect. We value each and every customer and deliver world-calss customer experience.",
      id: "3"
      
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <Heading>
          <SlantedBackground>{heading}</SlantedBackground>
        </Heading>
        {subheading && <Vision>{subheading}</Vision>}
        <Values>
          {values.map((value) => (
            <ValueContainer key={value.id}>
              <Value>
                <TitleText>{value.visionTitle}</TitleText>
                <Text>{value.text}</Text>
              </Value>
            </ValueContainer>
          ))}
        </Values>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
