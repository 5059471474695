import React from "react";
import Footer from "helpers/Footer";
import ServicesCallOutHero from "./services/ServicesComponents/ServicesCallOutHero";
import ServicesCallOutInfo from "./services/ServicesComponents/ServicesCallOutInfo";

export default() => {
    return(
        <>
                <ServicesCallOutHero />
                <ServicesCallOutInfo />
                <Footer />
        </>
    )
}