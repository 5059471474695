import Footer from "helpers/Footer";
import React from "react";
import ServicesLeadGenerationHero from "./services/ServicesComponents/ServicesLeadGenerationHero";
import ServicesLeadGenerationInfo from "./services/ServicesComponents/ServicesLeadGenerationInfo";
import ServicesLeadGenerationSteps from "./services/ServicesComponents/ServicesLeadGenerationSteps";
import ServicesLeadGenerationSwiper from "./services/ServicesComponents/ServicesLeadGenerationSwiper";


export default() => {
    return(
        <>
            <ServicesLeadGenerationHero />
            <ServicesLeadGenerationSwiper />
            <ServicesLeadGenerationSteps />
            <ServicesLeadGenerationInfo />
            <Footer />
        </>
    )
}
