import React from "react";
import tw from "twin.macro";
import { SectionHeading, Subheading } from "../../../misc/Headings";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg"
import CristieTestiImage from "images/cristie-isa.jpg"

const Container = tw.div``
const Heading = tw(SectionHeading)`text-xl sm:text-2xl lg:text-3xl text-center leading-tight -mx-8 py-8 text-primary-500`;
const DescriptionHeading = tw.p`text-center md:text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 md:px-16 mt-4`
const SubheadingCristie = tw(Subheading)`pb-4`
const DescriptionCristie = tw.p`text-center md:text-right text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 pb-4 md:pb-16`
const SubheadingCovid = tw(Subheading)`pb-4`
const DescriptionCovid = tw.p`text-center md:text-right text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const LeftColumn = tw(Column)`md:w-8/12 h-80`;

const ImageColumn = tw(Column)`pl-6 md:w-4/12 md:ml-auto lg:mr-0 mt-56 md:mt-0 lg:ml-32`;
const ImageContainer = tw.div`flex flex-col `;
const Image = tw.img`max-w-full w-96 rounded-t relative`;

const Testimonial = tw.div`max-w-sm bg-primary-500 rounded-b p-4`
const Quote = tw.blockquote`text-gray-300`
const CustomerName = tw.p`mt-4 font-bold text-gray-300`
const CustomerCompany = tw.p`mt-1 text-sm text-gray-100`


export default({
    imageSrc = CristieTestiImage,
    testimonial = {
        quote: "Think Pipeline met our key criteria, particularly the ability to utilise its own very large, comprehensive, database. Thanks to Think Pipeline’s experience in B2B enterprise technology sales, plus its experience working with HPE, the training requirement was close to zero for the HCI 2.0 solutions. In addition, the majority of their agents have experience of working in the technology business.",
        customerName: "Isa Pandor",
        customerCompany: "Digital Marketing Executive at Cristie Data"
      }

}) => {
    return(
        <>
            <Container>
                <Heading>About the Company</Heading>
                <DescriptionHeading>Cristie Data is an independent data storage infrastructure specialist, working with leading vendors such as HPE, DellEMC, arcserve, Nutanix, Barracuda and more. Part of the iomart group of companies, Cristie Data is headquartered in Stroud, Gloucestershire, and is dedicated to helping customers save time, reduce costs, and improve data storage capabilities.</DescriptionHeading>
                <TwoColumn>
                    <LeftColumn>
                        <SubheadingCristie>How they generate new business</SubheadingCristie>
                        <DescriptionCristie>Cristie Data regularly exhibited at industry events – typically three major shows each year – to recruit new sales prospects. The company targets commercial and public-sector organisations, aiming to speak with IT managers, IT directors, and those with direct operational data storage responsibilities.</DescriptionCristie>
                        <SubheadingCovid>Covid-19 impact</SubheadingCovid>
                        <DescriptionCovid>When Covid-19 lockdowns resulted in the cancellation of exhibitions, the Cristie Data team immediately recognised that its future sales pipeline was at risk. Participating in events is a relatively expensive exercise, costing up to £30,000 for the exhibition space and stand, plus further costs for accommodation, travel, and branded merchandise. To balance the equation, the opportunities collected at trade shows were generally of good quality, and conversion rates were high.</DescriptionCovid>
                    </LeftColumn>
                    <ImageColumn>
                        <ImageContainer>
                            <Image src={imageSrc} />
                            <Testimonial>
                            <Quote>"{testimonial.quote}"</Quote>
                            <CustomerName>{testimonial.customerName}</CustomerName>
                            <CustomerCompany>{testimonial.customerCompany}</CustomerCompany>
                            </Testimonial>
                        </ImageContainer>
                    </ImageColumn>
                </TwoColumn>
            </Container>
        </>
    )
}