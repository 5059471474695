import Hero from "./home/Hero";
import About from "./home/About";
import Services from "./home/Services";
import Contact from "./home/Contact";
import Footer from "../helpers/Footer";
import FAQ from "./home/FAQ";

export default() => {
    return(
        <>
            <Hero />
            <About />
            <Services />
            <FAQ />
            <Contact />
            <Footer />
        </>
    );
};