import React from "react";
import tw from "twin.macro";
import MapContainer from "helpers/MapContainer";


// const Container = tw.div`relative pl-8 px-8 mt-8 mx-auto pb-16 bg-primary-500`;
const Container = tw.div`w-full`
const TwoColumn = tw.div`lg:flex flex-col items-center text-center lg:flex-row lg:justify-between md:pb-24 2xl:pb-64 md:mx-16 xl:mx-32`;
const RightColumn = tw.div`pt-24 md:pt-32 lg:mr-16`;
const LeftColumn = tw.div`md:mr-auto invisible lg:visible`;
const Heading = tw.div`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight mb-4 pt-12`;
const Details = tw.p`font-bold text-base lg:text-lg xl:text-xl text-secondary-500`



export default function ContactHero() {
  return (
    <>
        <Container>
            <TwoColumn>
                <LeftColumn>
                     <MapContainer />
                </LeftColumn>
                <RightColumn>
                    <Heading>
                        Our Address  
                    </Heading>
                    <Details>
                        One Crown Square, Suite 7.03
                        <br />
                        Church Street East
                        <br />
                        Woking GU21 6HR, UK.
                    </Details>
                </RightColumn>   
            </TwoColumn>
        </Container>
    </>
  );
};
