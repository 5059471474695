import React from "react";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "../../../misc/Headings";
import {ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import styled from "styled-components";
import FillerImage from "../../../images/filler-image.jpg"
import FillerImage1 from "../../../images/filler-image1.jpg"

const Component = tw.div``;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Subheading = tw(SubheadingBase)`text-center md:text-left font-bold`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const ListItems = tw.ul`md:list-disc mt-4 md:ml-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`,
]);

export default({
    heading = "The Benefits Of Account Based Marketing:",
    subheading = "Return on Investment",
    description = "Think Pipeline’s Daily Service Rate (DSR) methodology delivers higher ROI than the traditional Pay Per Appointment (PPA). Unlike PPA, which only delivers generated leads, ignoring any pipeline opportunities, DSR delivers leads, AND qualified pipeline. The additional pipeline includes nurtures and all conversation intellect, providing our customers with leads AND a qualified pipeline.",
    heading1 = "Guaranteed Results",
    subheading1 = "Need A More Proactive, Targeted Approach?",
    description1 = "Account Based Marketing is key to any sales organisation to maintain/expand relationships within existing customers, or to develop awareness and a rapport with target prospects. Utilising the expertise of Think Pipelines’ demand generation team facilitates the continuance or building of trusted relationships with your key accounts, so when they are ready to buy, your organisation and products are front-of-mind. We identify the right touch points for you, create a targeted Account Based Marketing campaign, and manage the process from customer awareness through to qualified, sales-ready opportunities.",
    imageRounded = true,
    imageBorder = false,
    imageShadow = false,
    imageCss = null,
    imageDecoratorBlob = false,
    imageDecoratorBlobCss = null,
    textOnLeft = true,

}) => {
    const details = [
        {
            id: 1,
            detail: "ROI is easy to measure and provides better growth insight"
        },
        {
            id: 2,
            detail: "Better equipped to make more informed decisions"
        },
        {
            id: 3,
            detail: "Prospects respond positively to relevant and personal interaction"
        },
        {
            id: 4,
            detail: "Goal setting and analytics is easier and clearer"
        },
        {
            id: 5,
            detail: "Shorten sales cycles by eliminating unqualified prospects early on, preserving company resources"
        },
        {
            id: 6,
            detail: "Generates higher value sales opportunities"
        },
        {
            id: 7,
            detail: "Longer customer retention."
        },
    ];

    const itemList = details.map((item) => (
        <li key={item.id}>{item.detail}</li>
    ));
    return(
        <>
            <Component>
                <TwoColumn>
                    <ImageColumn>
                        <Image css={imageCss} src={FillerImage} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
                    </ImageColumn>
                    <TextColumn textOnLeft={textOnLeft}>
                        <TextContent>
                            <Subheading>{subheading}</Subheading>
                            <Heading>{heading}</Heading>
                            <ListItems>{itemList}</ListItems>
                        </TextContent>
                    </TextColumn>
               </TwoColumn>
                <TwoColumn>
                    <ImageColumn>
                        <Image css={imageCss} src={FillerImage1} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
                    </ImageColumn>
                    <TextColumn>
                        <TextContent>
                            <Subheading>{subheading1}</Subheading>
                            <Heading>{heading1}</Heading>
                            <Description>{description1}</Description>
                        </TextContent>
                    </TextColumn>
               </TwoColumn>
            </Component>
        </>
    )
}