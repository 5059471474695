import Footer from "helpers/Footer";
import React from "react";
import ServicesABMHero from "./services/ServicesComponents/ServicesABMHero";
import ServicesABMInfo from "./services/ServicesComponents/ServicesABMInfo";
import ServicesABMSteps from "./services/ServicesComponents/ServicesABMSteps";
import ServicesLeadGenerationSwiper from "./services/ServicesComponents/ServicesLeadGenerationSwiper";


export default() => {
    return(
        <>
            <ServicesABMHero />
            <ServicesLeadGenerationSwiper />
            <ServicesABMSteps />
            <ServicesABMInfo />
            <Footer />
        </>
    )
}