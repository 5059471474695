import React from "react";
import salesGraph from "../../images/salesGraph.jpg"
import salesManagers from "../../images/salesManagers.jpg"
import ServicesItem from "./ServicesItem";


export default () => {
  return (
    <>
      <ServicesItem 
        subheading="Guaranteed Sales-Ready"
        heading="Lead Generation"
        description = "We understand lead generation is key to business growth. We are also aware that it is more about lead quality than just quantity. Think Pipelines focus is generating sales ready leads with prospects that are currently in the market for your services – adding most value to your sales funnel now."
        imageSrc={salesGraph}
        buttonRounded={false}
        primaryButtonUrl = "/services-lead-generation"
      />
      <ServicesItem 
        subheading="Proactive and targeted"
        heading="Demand Generation & Account Based Marketing"
        description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        imageSrc={salesManagers}
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonUrl = "/services-ABM"
      />
      <ServicesItem 
        subheading="Building relationships with"
        heading="Channel Development"
        description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        imageSrc={salesGraph}
        buttonRounded={false}
        primaryButtonUrl = "/services-channel-development"
      />
        <ServicesItem 
        subheading="Supporting your company with our"
        heading="Data Services"
        description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        imageSrc={salesManagers}
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonUrl = "/services-data"
      />
      <ServicesItem 
        subheading="Scale-up your Inside Sales with"
        heading="Outsource Inside Sales"
        description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        imageSrc={salesGraph}
        buttonRounded={false}
        primaryButtonUrl = "/services-inside-sales"
      />
        <ServicesItem 
        subheading="Boost your sales with"
        heading="On-Premise Call Out Days"
        description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        imageSrc={salesManagers}
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonUrl = "/services-call-out"
      />
    </>
  );
}
