import Footer from "helpers/Footer"
import CareersContact from "./careers/CareersContact"
import CareersHero from "./careers/CareersHero"
import CarrersRoles from "./careers/CarrersRoles"

export default() => {
    return(
        <>
            <CareersHero />
            <CarrersRoles />
            <CareersContact />
            <Footer />
        </>
    )
}