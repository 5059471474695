import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import './App.css';
import Home from "components/Home";
import About from "components/About";
import Services from "components/Services";
import CaseStudies from "components/CaseStudies";
import Careers from "components/Careers";
import Contact from "components/Contact";
import ServicesLeadGeneration from "components/ServicesLeadGeneration";
import ServicesABM from "components/ServicesABM";
import ServicesChannelDevelopment from "components/ServicesChannelDevelopment";
import ServicesInsideSales from "components/ServicesInsideSales";
import ServicesDataServices from "components/ServicesDataServices";
import ServicesCallOut from "components/ServicesCallOut";
import CaseStudiesBarracuda from "components/CaseStudiesBarracuda";
import CaseStudiesCristieData from "components/CaseStudiesCristieData";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import PrivacyPolicy from "components/legal/PrivacyPolicy";
import TermsOfService from "components/legal/TermsOfService";
import ScrollToTop from "helpers/ScrollToTop";
import Navbar1 from "helpers/Navbar/Navbar1";



function App() {

  return (
    <>
      <Router>
      <Navbar1 />
        <ScrollToTop />
        <Switch>
          <Route path="/about">
             <About />
          </Route>
          <Route path="/services">
             <Services />
          </Route>
              <Route path="/services-lead-generation">
                <ServicesLeadGeneration />
              </Route>
              <Route path="/services-ABM">
                <ServicesABM />
              </Route>
              <Route path="/services-channel-development">
                <ServicesChannelDevelopment />
              </Route>
              <Route path="/services-data">
                <ServicesDataServices />
              </Route>
              <Route path="/services-inside-sales">
                <ServicesInsideSales />
              </Route>
              <Route path="/services-call-out">
                <ServicesCallOut />
              </Route>
          <Route path="/case-studies">
            <CaseStudies />
          </Route>
              <Route path="/case-studies-barracuda">
                <CaseStudiesBarracuda />
              </Route>
              <Route path="/case-studies-cristie-data">
                <CaseStudiesCristieData />
              </Route>
          <Route path="/careers">
            <Careers />
          </Route>
          <Route path="/contact-us">
            <Contact />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/terms-of-service">
            <TermsOfService />
          </Route>
           <Route path="/">
            <Home />
           </Route>
        </Switch>
      </Router>
      <CookieConsent
        debug={true}
        expires={365}
        buttonStyle={{color: "#000", background: "#fff", fontSize: "16px"}}
        buttonText="Agree"
      >This website uses Cookies to improve user experience, you can find more in our <a href="/privacy-policy">Privacy Policy</a></CookieConsent>
      </>
  );
}

export default App;
