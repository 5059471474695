import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Container = styled.div`
  ${tw`py-40 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;
const Heading = tw.h1`text-3xl text-gray-100 py-8 mt-8 font-black text-center`;
const Subheading = tw.h2`text-2xl text-gray-100 py-8 text-center`;;

export default({ 
    heading = "Channel Development",
    subheading = "Recruiting and Motivating Channel Partners",
    }) => {
    return(
        <>
            <Container>
                <Heading>
                    {heading}
                </Heading>
                <Subheading>
                    {subheading}
                </Subheading>
            </Container>
        
        </>
    )
}
