import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "../../../misc/Headings";
import { SectionDescription } from "../../../misc/Typography.js";
import StepsIcon from "../../../images/steps.png"
import StepsIcon1 from "../../../images/steps1.png"
import StepsIcon2 from "../../../images/steps2.png"

const Heading = tw(SectionHeading)`mt-4 md:mt-16`;
const Description = tw(SectionDescription)`text-center mx-auto max-w-screen-lg mt-4 md:mt-16`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto pb-8`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs max-w-md max-w-lg`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 py-8 rounded`}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .subtitle {
    ${tw`mt-4 font-semibold text-base leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

export default ({
  cards = [
    {
      imageSrc: StepsIcon,
      title: "Step 1",
      subtitle: "Preparing and researching the data",
      description: "Think Pipeline has proven that working with vendors to produce sales ready leads to put through new partners has a very positive initial impact on the Partner Management and sales teams.",

    },
    {
      imageSrc: StepsIcon1,
      title: "Step 2",
      subtitle: "Building Strong Relationships",
      description: "Think Pipeline has again proven that working with vendors to offer new partners a combination of targeted pipeline generation and carrying out on-premise call out/call coaching days, working alongside partner inside sales/sales teams, boosts their pipeline and builds trust and mind-share.",

    },
    {
      imageSrc: StepsIcon2,
      title: "Step 3",
      subtitle: "Maintaining Partner Loyalty",
      description: "By implementing a quarterly program of lead generation with on-premise call out days, the Partner Sales teams continue to benefit from ongoing sales-ready leads and are motivated to continue selling your products.",

    }
  ],
  heading = "Steps involved",
  description = "Demand generation is the process of getting your customers and prospects interested in what you have to sell – creating demand. We take a consultative approach to demand generation, working with you to define a bespoke Account Based Marketing campaign.  Through social selling, video, email and phone we personalise the demand generation process, from creating awareness, through to sales qualified leads.",
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <>
        {description && <Description>{description}</Description>}
        {heading && <Heading>{heading}</Heading>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <span className="subtitle">{card.subtitle}</span>
                <p className="description">{card.description}</p>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
    </>
  );
};
