import React from "react";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "../../../misc/Headings";
import {ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import styled from "styled-components";
import FillerImage from "../../../images/filler-image.jpg"
import FillerImage1 from "../../../images/filler-image1.jpg"





const Component = tw.div``;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Subheading = tw(SubheadingBase)`text-center md:text-left font-bold`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const ListItems = tw.ul`md:list-disc mt-4 ml-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
    tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const Image = styled.img(props => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`,
]);




export default({
    heading = "The benefits of using our expertise:",
    subheading = "",
    heading1 = "Guaranteed Results",
    subheading1 = "Need an experienced sales team?",
    description1 = "Our experienced on-prem team are deployed to your site and work alongside your sales teams on a targeted call-out campaign whilst providing your team with an expert pair of hands to help motivate and, where needed train, your pre-sales/sales team.",
    imageRounded = true,
    imageBorder = false,
    imageShadow = false,
    imageCss = null,
    imageDecoratorBlob = false,
    imageDecoratorBlobCss = null,
    textOnLeft = true,
}) => {
    const details = [
        {
            id: 1,
            detail: "Instant sales pipeline boost"
        },
        {
            id: 2,
            detail: "Hands on Insides Sales coaching"
        },
        {
            id: 3,
            detail: "Channel Partner on-boarding and buy-in"
        },
        {
            id: 4,
            detail: "Fast-start campaign launch"
        },
        {
            id: 5,
            detail: "Motivational Team building"
        },
        {
            id: 6,
            detail: "Annual holiday cover"
        },
    ]

    const listItems = details.map((item) =>( 
        <li key={item.id}>{item.detail}</li>
    ));
    return(
        <>
            <Component>
                <TwoColumn>
                    <ImageColumn>
                        <Image css={imageCss} src={FillerImage} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
                        {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
                    </ImageColumn>
                    <TextColumn textOnLeft={textOnLeft}>
                        <TextContent>
                            <Subheading>{subheading}</Subheading>
                            <Heading>{heading}</Heading>
                            <ListItems>{listItems}</ListItems>
                        </TextContent>
                    </TextColumn>
               </TwoColumn>
                <TwoColumn>
                    <ImageColumn>
                        <Image css={imageCss} src={FillerImage1} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
                        {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
                    </ImageColumn>
                    <TextColumn>
                        <TextContent>
                            <Subheading>{subheading1}</Subheading>
                            <Heading>{heading1}</Heading>
                            <Description>{description1}</Description>
                        </TextContent>
                    </TextColumn>
               </TwoColumn>
            </Component>
        </>
    )
}