import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";

const Container = tw.div`relative flex flex-col pt-40 text-center content-center max-w-lg mx-auto lg:max-w-none h-full`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight px-10 `;
const Paragraph = tw.p`my-5 pb-8 lg:my-8 text-base xl:text-lg`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

export default () => {
  return (
    <>
      <Container>
            <Heading>
              We had some great success with our partnered companies. <span tw="text-primary-500">Here are their stories.</span>
            </Heading>
            <Paragraph>
            We deliver Guaranteed, High Quality sales opportunities with clear ROI
            </Paragraph>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
