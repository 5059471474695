import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "../../misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../../misc/Buttons.js";

const Container = tw.div`bg-gray-100 py-8 content-center justify-center pr-16`;
const Header = tw.div`w-full m-8 text-center`;
const Heading = tw(SectionHeading)`w-full my-8 text-4xl`
const Subheading = tw(SubheadingBase)`text-2xl`;
const Details = tw.p`text-xl mt-8 md:mt-10 text-center`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-2 md:mt-4 text-sm inline-block mx-auto md:mx-0 text-center`;

const ContactDetailsContainer = tw.div`md:flex md:flex-row md:justify-center md:gap-32 md:pt-4`
const ContactDetails = tw.div`p-12 rounded shadow-xl`

export default () => {
    return(
        <>
            <Container>
                <Header>
                    <Heading>To learn more about the jobs available, contact</Heading>
                    <ContactDetailsContainer>
                        <ContactDetails>
                            <Details>Please send your CV to</Details>
                            <PrimaryButton onClick={() => window.location = 'mailto:becci.campbell@thinkpipeline.co.uk'}>Send email</PrimaryButton>
                        </ContactDetails>
                        <ContactDetails>
                            <Details>Or call</Details>
                            <Subheading>07972 118838 (Becci)</Subheading>
                        </ContactDetails>
                    </ContactDetailsContainer>
                </Header>
            </Container>
        </>
    )
}