import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import LeadGenerationImageHero from "../../../images/hands-up2.jpg"

const Container = styled.div`
  ${tw`py-40 bg-center bg-cover`}
  background-image: url(${LeadGenerationImageHero});
`;

const Heading = tw.h1`text-3xl text-gray-100 py-8 mt-8 font-black text-center`;
const Subheading = tw.h2`text-2xl text-gray-100 py-8 text-center`;

export default({ 
    heading = "Lead Generation",
    subheading = "Guaranteed Sales-Ready leads with our IT B2B Lead Generation Service",
    }) => {
    return(
        <>
            <Container>
                    <Heading>
                        {heading}
                    </Heading>
                    <Subheading>
                        {subheading}
                    </Subheading>
            </Container>
        
        </>
    )
}
