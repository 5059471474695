import React from "react";
import Footer from "helpers/Footer";
import CaseStudiesBarracudaHero from "./case-studies/CaseStudiesComponents/CaseStudiesBarracudaHero";
import CaseStudiesBarracudaInfo from "./case-studies/CaseStudiesComponents/CaseStudiesBarracudaInfo";
import CaseStudiesBarracudaDetails from "./case-studies/CaseStudiesComponents/CaseStudiesBarracudaDetails";
import CaseStudiesBarracudaPartner from "./case-studies/CaseStudiesComponents/CaseStudiesBarracudaPartner";



export default() => {
    return(
        <>
            <CaseStudiesBarracudaHero />
            <CaseStudiesBarracudaDetails />
            <CaseStudiesBarracudaInfo />
            <CaseStudiesBarracudaPartner />
            <Footer />
        </>
    )
}