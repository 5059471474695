import CaseStudiesHero from "./case-studies/CaseStudiesHero"
import CaseStudiesPortfolio from "./case-studies/CaseStudiesPortfolio"
import Footer from "../helpers/Footer"
import CaseStudiesTestimonials from "./case-studies/CaseStudiesTestimonials";
import ServicesLeadGenerationSwiper from "../components/services/ServicesComponents/ServicesLeadGenerationSwiper"

export default () => {
    return(
        <>
            <CaseStudiesHero />
            <ServicesLeadGenerationSwiper />
            <CaseStudiesPortfolio />
            <CaseStudiesTestimonials />
            <Footer />
        </>
    )
}