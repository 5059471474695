import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "../../misc/Headings.js";
import { SectionDescription } from "../../misc/Typography.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24 gap-y-8`}
`;
const Subheading = tw(SubheadingBase)``;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8 rounded shadow-2xl bg-secondary-300 group-hover:bg-secondary-100 transition ease-in duration-500`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none text-gray-100`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-gray-300 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ 
  cards = null, 
  heading = "Our Services", 
  subheading = "Learn about", 
  description = "Supporting your Start-Up, Sales Pipeline, Customer Retention and Channel Development strategies, plus Bespoke Services"
 }) => {

  const defaultCards = [
    {
      imageSrc: SupportIconImage,
      title: "Lead Generation",
      description: "Guaranteed Sales-Ready leads with our IT B2B Lead Generation Service",
      path: "/services-lead-generation", 
      id: "1"
    },
    { imageSrc: FastIconImage, 
      title: "ABM",
      description: "Proactive, targeted approach to Demand Generation & Account Based Marketing",
      path: "/services-ABM",
      id: "2"
    },
    { imageSrc: ShieldIconImage, 
      title: "Data Services",
      description: "Supporting your data requirements to facilitate targetted campaigns",
      path: "/services-channel-development",
      id: "3"
    },
    { imageSrc: ReliableIconImage, 
      title: "Outsourced Inside Sales",
      description: "Our sales specialists can support you through busy or challenging times",
      path: "/services-data",
      id: "4" 
    },
    { imageSrc: FastIconImage, 
      title: "On-Premise Days",
      description: "Call-out service delivered by our Senior Business Development Executives at your location",
      path: "/services-inside-sales",
      id: "5" 
    },
    { imageSrc: CustomizeIconImage, 
      title: "Channel Developement",
      description: "Our industry knowledge enables us to help you quickly identify potential new partners",
      path: "/services-call-out",
      id: "6" 
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card) => (
          <Column key={card.id} className="group">
            <Link to={card.path}>
              <Card>
                <span className="imageContainer">
                  <img src={card.imageSrc || defaultCardImage} alt="" />
                </span>
                <span className="textContainer">
                  <span className="title">{card.title || "Fully Secure"}</span>
                  <p className="description">
                    {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                  </p>
                </span>
              </Card>
            </Link>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
