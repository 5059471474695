import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../../../misc/Headings.js";
import { ReactComponent as QuotesLeftIcon } from "../../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../../images/quotes-r.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../../images/arrow-right-2-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../../images/svg-decorator-blob-5.svg";

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const TestimonialSliderContainer = tw.div`mt-8`;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;

const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-10/12 py-4 flex flex-col justify-between`;
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-2xl xl:text-3xl`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-sm`;

const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0">
    <button {...props}>
      <ArrowRightIcon />
    </button>
  </SliderControlButtonContainer>
);
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0">
    <button {...props}>
      <ArrowLeftIcon />
    </button>
  </SliderControlButtonContainer>
);

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default () => {
  /*
   * You can modify the testimonials shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  const testimonials = [
    {
      quote:
        "Think Pipeline is completely open and honest in its approach to fulfilling our needs, offering very experienced and invaluable direction on what’s possible, and helping us to establish a clear view on what are likely to be achievable targets. We very much appreciate Think Pipeline’s consultative style of approach, and positive proposals about how to achieve our objectives.",
      customerName: "Isa Pandor",
      customerTitle: "Digital Marketing Executive at Cristie Data"
    },
    {
      quote:
        "Though we have started attending events again, retaining Think Pipeline is a powerful addition to our lead-generating activities. In the past using only events and our vendors’ lead services, we found that we had peaks and troughs in our lead pipeline; some months we had too many leads to handle while at other times there was a lack of new leads, with Think Pipeline, we gain greater control of the flow, with our sales teams and Think Pipeline working in tandem far more effectively.",
      customerName: "Isa Pandor",
      customerTitle: "Digital Marketing Executive at Cristie Data"
    },
    {
      quote:
        "It’s refreshing that Think Pipeline takes a long-term approach, looking to establish a sustainable relationship and always seeking to find out how they can help us. For example, Think Pipeline keeps in regular contact with our team, following up on how the opportunities are progressing, and providing great insight into how they can help going forwards. Think Pipeline’s business model, based on whether the meeting takes place, shows that it is fully committed to discovering the right opportunities, which I think is unusual in this space, and hugely beneficial for Cristie Data.",
      customerName: "Isa Pandor",
      customerTitle: "Digital Marketing Executive at Cristie Data"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Cristie Data Feedback</HeadingTitle>
          <HeadingDescription>What did Cristie Data thought of our Service</HeadingDescription>
        </HeadingInfoContainer>
        <TestimonialSliderContainer>
          <TestimonialSlider nextArrow={<NextArrow />} prevArrow={<PreviousArrow />}>
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>
                {/* <ImageContainer>
                  <img src={testimonial.imageSrc} alt={testimonial.customerName} />
                </ImageContainer> */}
                <TextContainer>
                  <QuoteContainer>
                    <QuotesLeft />
                    <Quote>{testimonial.quote}</Quote>
                    <QuotesRight />
                  </QuoteContainer>
                  <CustomerInfo>
                    <CustomerName>{testimonial.customerName}</CustomerName>
                    <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                  </CustomerInfo>
                </TextContainer>
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
