export const MenuItems = [
    {
        title: "Lead Generation",
        path: "/services-lead-generation",
        cName: "dropdown-link"
    },
    {
        title: "ABM",
        path: "/services-ABM",
        cName: "dropdown-link"
    },
    {
        title: "Channel Development",
        path: "/services-channel-development",
        cName: "dropdown-link"
    },
    {
        title: "Data Services",
        path: "/services-data",
        cName: "dropdown-link"
    },
    {
        title: "Inside Sales",
        path: "/services-inside-sales",
        cName: "dropdown-link"
    },
    {
        title: "Call Out",
        path: "/services-call-out",
        cName: "dropdown-link"
    },
]

export const MenuItemsSources = [
    {
        title: "Case Studies",
        path: "/case-studies",
        cName: "dropdown-link"
    },
    {
        title: "Careers",
        path: "/careers",
        cName: "dropdown-link"
    },
    // {
    //     title: "Blog",
    //     path: "/blog",
    //     cName: "dropdown-link"
    // },
]