import Footer from "helpers/Footer"
import ContactDetails from "./contact/ContactDetails"
import ContactHero from "./contact/ContactHero"

export default() => {
    return(
        <>
            <ContactHero />
            <ContactDetails />
            <Footer />
        </>
    )
}