import React, { useState } from "react";
import tw from "twin.macro";
import ThinkPipelineLogo from "../../images/TP-logo-white.PNG";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuItemsSources } from "./MenuItems";
import "./Dropdown.css"



export default() => {
    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)

    return(
        <>
            <ul onClick={handleClick}
            className={click ? "dropdown-menu clicked" : "dropdown-menu"}
            >
                {MenuItemsSources.map((item, index) => {
                    return(
                        <li key={index}>
                            <Link className={item.cName} to={item.path} onClick={() => setClick(false)}>
                                {item.title}
                            </Link>
                        </li>
                    )
                })}
            </ul>       
        </>

    )
}
