import React from "react";
import tw from "twin.macro";
import { SectionHeading, Subheading } from "../../../misc/Headings";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg"
import BarracudaTestiImage from "images/barracuda-rebecca.jpg"

const Container = tw.div``
const Heading = tw(SectionHeading)`text-xl sm:text-2xl lg:text-3xl text-center leading-tight -mx-8 py-8 text-primary-500`;
const DescriptionHeading = tw.p`text-center md:text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 md:px-16 mt-4`
const SubheadingBarracuda = tw(Subheading)`pb-4`
const DescriptionBarracuda = tw.p`text-center md:text-right text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 pb-4 md:pb-16`
const SubheadingMicrosoft = tw(Subheading)`pb-4`
const DescriptionMicrosoft = tw.p`text-center md:text-right text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const LeftColumn = tw(Column)`md:w-8/12 h-80`;

const ImageColumn = tw(Column)`pl-6 md:w-4/12 md:ml-auto lg:mr-0 mt-56 md:mt-0 lg:ml-32`;
const ImageContainer = tw.div`flex flex-col `;
const Image = tw.img`max-w-full w-96 rounded-t relative`;

const Testimonial = tw.div`max-w-sm bg-primary-500 rounded-b p-4`
const Quote = tw.blockquote`text-gray-300`
const CustomerName = tw.p`mt-4 font-bold text-gray-300`
const CustomerCompany = tw.p`mt-1 text-sm text-gray-100`


export default({
    imageSrc = BarracudaTestiImage,
    testimonial = {
        quote: "We realised that we could hit our targets if we could find a lead-gen partner with the right prospect database, relevant enterprise IT experience and sheer capacity to meet the challenge.",
        customerName: "Rebecca Bailey",
        customerCompany: "UK Marketing Manager at Barracuda"
      }

}) => {
    return(
        <>
            <Container>
                <Heading>About the Company</Heading>
                <DescriptionHeading>More than 200,000 global customers trust Barracuda to safeguard their employees, data, and applications from a wide range of threats. Barracuda provides easy, comprehensive and affordable solutions for email protection, application and cloud security, network security and data protection. We are continually innovating to deliver tomorrow’s security technology, today</DescriptionHeading>
                <TwoColumn>
                    <LeftColumn>
                        <SubheadingBarracuda>Barracuda</SubheadingBarracuda>
                        <DescriptionBarracuda>Barracuda builds enterprise-grade, cloud-ready security solutions. The company sells exclusively through its extensive network of business partners, who generate leads, complete sales, and deploy solutions, while Barracuda focuses on product excellence and customer support. As a key Microsoft Partner, Barracuda and Microsoft share an excellent relationship that benefits both organisations in the continued development and sale of innovative security solutions.</DescriptionBarracuda>
                        <SubheadingMicrosoft>Microsoft and Barracuda</SubheadingMicrosoft>
                        <DescriptionMicrosoft>Microsoft supports Barracuda with MDF, to help generate a healthy sales pipeline. Rebecca Bailey, UK Marketing Manager at Barracuda, explains the situation: “The opportunity arose to promote the Barracuda Cloud-to-Cloud Backup service for Microsoft Office 365. This would be supported by $20,000 of MDF from Microsoft if Barracuda matched the budget. Understandably, Microsoft asked for specific return on investment in terms of new leads, with detailed parameters for its target sector and value of each deal. Yet we had only slightly longer than a month before the promotion ended, with essentially only a week to prepare the campaign.”</DescriptionMicrosoft>
                    </LeftColumn>
                    <ImageColumn>
                        <ImageContainer>
                            <Image src={imageSrc} />
                            <Testimonial>
                            <Quote>"{testimonial.quote}"</Quote>
                            <CustomerName>{testimonial.customerName}</CustomerName>
                            <CustomerCompany>{testimonial.customerCompany}</CustomerCompany>
                            </Testimonial>
                        </ImageContainer>
                    </ImageColumn>
                </TwoColumn>
            </Container>
        </>
    )
}