import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../../misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../../misc/Buttons.js";
import { ReactComponent as BriefcaseIcon } from "../../images/briefcase-icon.svg";
import { ReactComponent as TargetIcon } from "../../images/target-icon.svg";
import { ReactComponent as UsersIcon } from "../../images/users-icon.svg";
import FadeIn from "react-fade-in/lib/FadeIn";

import LindaProfileImage from "../../images/Linda-Profile.jpg";
import AthenaProfileImage from "../../images/Ethena-Profile.jpg";
import ReesProfileImage from "../../images/Rees-Profile1.jpg"


const Container = tw.div`relative text-gray-100 bg-primary-500`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-primary-100`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Heading1 = tw(
  SectionHeading
)`mt-4 md:-mb-16 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-200`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-400 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-100`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-100`;
const FeatureDescription = tw.div`mt-1 text-sm`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0 bg-primary-400`;

const CardsColumn = tw.div`max-w-xl`
const Cards = tw.div`flex flex-wrap flex-row`
const Card = tw.div`mt-8 md:mt-32 w-full sm:w-1/2 lg:w-1/2 flex flex-col items-center`

const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-56 h-48 bg-contain bg-center rounded bg-no-repeat`}`


const CardContent = styled.div`
  ${tw`flex flex-col items-center text-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-100`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  }
  .description { 
    ${tw`text-base text-gray-900 mx-4`}
  }
`

const CardDescription = tw.span`text-base opacity-0 text-gray-900 mx-4 group-hover:opacity-100 transition ease-in duration-500`

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Our <span tw="text-primary-200">professional</span> marketing team will open doors, build rapport and discover opportunities for your business.
    </>
  ),
  description = "Grow sales, improve customer loyalty and increase brand exposure using our specialist services. Finding qualified sales opportunities can be difficult, which is why Think Pipeline offers a range of different services to suit your business, including high quality IT B2B lead generation and demand generation, new business development and telemarketing. Our core values are:",
  primaryButtonText = "Discover more",
  primaryButtonUrl = "/about",
  features = null,
  textOnLeft = true,

  cards = [
    {
      imageSrc: LindaProfileImage,
      position: "Owner/CEO",
      name: "Linda Brown",
      description: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsuml orem ipsum", 
      id: "1"
    },
    {
      imageSrc: ReesProfileImage,
      position: "Operations Manager",
      name: "Farzad Gharegezloo",
      description: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsuml orem ipsum",
      id: "2"
    },
    {
      imageSrc: ReesProfileImage,
      position: "Sales Manager",
      name: "Rees Collins",
      description: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsuml orem ipsum",
      id: "3"
    },
    {
      imageSrc: AthenaProfileImage,
      position: "Account Manager",
      name: "Athena Eleftheriou",
      description: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsuml orem ipsum",
      id: "4"
    },
  ]
}) => {

  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: "Professionalism",
      description: "We commit to an honest and transparent working relationship",
      id: "1"
    },
    {
      Icon: TargetIcon,
      title: "Target Oriented",
      description: "We are highly committed to making your business more successful",
      id: "2"
    }, 
    {
      Icon: UsersIcon,
      title: "Dedication",
      description: "We endeavour to employ the best business development individuals across UK, EMEA, USA and Canada",
      id: "3" 
    }
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <FadeIn visible={true}>
      <TwoColumn>
        <CardsColumn>
          <Heading1>Management Team</Heading1>
          <Cards>
            {cards.map((card) => (
              <Card key={card.id} className="group">             
                  <CardImage imageSrc={card.imageSrc} />                 
                <CardContent >
                    <span className="position">{card.position}</span>
                    <span className="name">{card.name}</span>
                    <CardDescription>{card.description}</CardDescription>
                  </CardContent>
              </Card>
              ))}
          </Cards>
        </CardsColumn>
        
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature) => (
                <Feature key={feature.id}>
                  <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>{feature.description}</FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
            <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
        </FadeIn>
    </Container>
  );
};
