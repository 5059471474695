import React from "react";
import tw from "twin.macro";
import ServicesSalesHero from "./services/ServicesComponents/ServicesSalesHero";
import ServicesLeadGenerationSwiper from "./services/ServicesComponents/ServicesLeadGenerationSwiper"
import Footer from "../helpers/Footer";
import ServicesSalesInfo from "./services/ServicesComponents/ServicesSalesInfo";

export default() => {
    return(
        <>
                <ServicesSalesHero />
                <ServicesLeadGenerationSwiper />
                <ServicesSalesInfo />
                <Footer />
        </>
    )
}