import AboutCompany from "./about/AboutCompany";
import AboutHero from "./about/AboutHero";
import AboutValues from "./about/AboutValues";
import AboutTeam from "./about/AboutTeam"
import Footer from "../helpers/Footer";

export default() => {
    return(
        <>
            <AboutHero />
            <AboutCompany />
            <AboutValues />
            <AboutTeam />
            <Footer />
        </>
    );
};