import React, { useState } from "react";
import tw from "twin.macro";
import Dropdown from "./Dropdown";
import { Link } from "react-router-dom";
import './Navbar.css';
import logo from "../../images/TP-logo-white.PNG";
import DropdownSources from "./DropdownSources";



const Container = tw.nav`flex bg-primary-600 z-50 fixed h-16 w-full place-items-center`;
const LogoImage = tw.img`m-4 md:ml-8 lg:ml-16 2xl:ml-32 h-16 justify-start`

export default function Navbar1() {
    const[click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false)

    const [dropdownSources, setDropdownSources] = useState(false)

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false)

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
          setDropdown(false);
        } else {
          setDropdown(true);
        }
      };
    
    const onMouseLeave = () => {
    if (window.innerWidth < 960) {
        setDropdown(false);
    } else {
        setDropdown(false);
    }
    };

    const onMouseEnterSources = () => {
        if (window.innerWidth < 960) {
          setDropdownSources(false);
        } else {
            setDropdownSources(true);
        }
      };
    
    const onMouseLeaveSources = () => {
    if (window.innerWidth < 960) {
        setDropdownSources(false);
    } else {
        setDropdownSources(false);
    }
    };

 

    return(
            <>
              <Container>
                <Link to='/' onClick={closeMobileMenu}>
                  <LogoImage src={logo}></LogoImage>
                </Link>
                    <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>

                      <li className='nav-item'>
                        <Link 
                            to='/' 
                            className='nav-links' 
                            onClick={closeMobileMenu}
                            >
                          Home
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link 
                            to='/about' 
                            className='nav-links' 
                            onClick={closeMobileMenu}
                            >
                          About Us
                        </Link>
                      </li>
                      <li
                        className='nav-item'
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      >
                        <Link
                          to='/services'
                          className='nav-links'
                          onClick={closeMobileMenu}
                        >
                          Services <i className='fas fa-caret-down link-icon' />
                        </Link>
                        {dropdown && <Dropdown />}
                      </li>
                      <li 
                        className='nav-item'
                        onMouseEnter={onMouseEnterSources}
                        onMouseLeave={onMouseLeaveSources}
                        >
                        <Link
                          to='/case-studies'
                          className='nav-links'
                          onClick={closeMobileMenu}
                        >
                          Sources <i className='fas fa-caret-down' />
                        </Link>
                        {dropdownSources && <DropdownSources />}
                      </li>
                      <li className='nav-item'>
                        <Link
                          to='/contact-us'
                          className='nav-links'
                          onClick={closeMobileMenu}
                        >
                          Contact Us
                        </Link>
                      </li>
                </ul>
              </Container>
            </>
          );
        }


   