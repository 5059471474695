import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../../misc/Headings.js";
import { SectionDescription } from "../../misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "../../misc/Buttons.js";
import { Container as ContainerBase, ContentWithPaddingXl as ContentBase } from "../../misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";

const Container = tw(ContainerBase)`bg-primary-500 text-gray-100 justify-center`;
const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 sm:px-6 md:px-8 lg:px-12 xl:px-24 sm:py-20 max-w-screen-xl items-center`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center text-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100 `;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-gray-300`;

const PlansContainer = tw.div`mt-16 grid grid-cols-1 gap-6 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-3 justify-items-center text-gray-900 font-medium text-center`;
const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 flex flex-col mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`lg:text-xl xl:text-xl font-bold uppercase tracking-wider mr-3 justify-items-center`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .salaryContainer {
    ${tw`mt-6 text-base font-bold content-center`}
  }
  .description {
    ${tw`mt-8 font-medium text-gray-700 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-primary-900 tracking-wide ml-3`}
    }
  }
`;

export default ({
  subheading = "",
  heading = "Roles and Benefits",
  description = "Work for a growing, successful and fun company and have the opportunity to work with our clients who are some of the biggest names in the IT sector.",
  plans = null,
}) => {
  const defaultPlans = [
    {
      name: "Business Development Executive - English Speaker",
      salary: "£18,000 - £35,000 per annum, dependent on experience",
      description: "Assigned to specific client campaigns making outbound calls to English speaking end-user mid-market and enterprise-level companies",
      features: [
            "Making outbound calls", 
            "Manage prospects database", 
            "Reporting and fact-finding", 
            "Achieve and exceed set targets", 
            "Develop relationships"
      ],
      id: 1
    },
    {
      name: "Business Development Executive - German Speaker",
      salary: "£18,000 - £35,000 per annum, dependent on experience",
      description: "Assigned to specific client campaigns making outbound calls to German speaking end-user mid-market and enterprise-level companies",
      features: [
            "Making outbound calls", 
            "Manage prospects database", 
            "Reporting and fact-finding", 
            "Achieve and exceed set targets", 
            "Develop relationships"
      ],
      id: 2
    },
    {
      name: "Business Development Executive - Dutch Speaker",
      salary: "£18,000 - £35,000 per annum, dependent on experience",
      description: "Assigned to specific client campaigns making outbound calls to Dutch speaking end-user mid-market and enterprise-level companies",
      features: [
            "Making outbound calls", 
            "Manage prospects database", 
            "Reporting and fact-finding", 
            "Achieve and exceed set targets", 
            "Develop relationships"
      ],
      id: 3,
    },
    {
      name: "Sales Administrator",
      salary: "£18,000 - £22,000 per annum",
      description: "Perfect for hosting blogs with lots of traffic or heavy development projects",
      features: [
        "Daily admin tasks",
        "Maintain CRM database",
        "Manage Google Sheets",
        "Update and process database",
        "Daily data audits",
        "Research Linkedin and Lusha",
        "Meet and exceed activity targets"
      ],
      id: 4,
    },
    {
      name: "Lorem ipsum dolor sit amet",
      salary: "£18,000 - £22,000 per annum",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      features: [
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
        "Lorem ipsum dolor sit amet",
      ],
      id: 5,
    },
  ];

  if (!plans) plans = defaultPlans;

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, i) => (
            <Plan key={i} featured={plan.featured}>
              <PlanHeader>
                <span className="nameAndFeaturedContainer">
                  <span className="name">{plan.name}</span>
                </span>
                <div className="salaryContainer">   
                    {plan.salary}
                </div>
                <p className="description">{plan.description}</p>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <li className="feature" key={index}>
                    <CheckboxIcon className="icon" />
                    <span className="text">{feature}</span>
                  </li>
                ))}
              </PlanFeatures>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
