import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { css } from "styled-components";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import tw from "twin.macro";
import "../../../styles/styles.css"

// import required modules
import { EffectCoverflow, Pagination } from "swiper";
import SwiperCore, { Autoplay } from 'swiper';

// Import pictures
import Amd from "../../../images/amd.jpg";
import Armis from "../../../images/armis.jpg";
import Atos from "../../../images/atos.jpg";
import Barracuda from "../../../images/barracuda.jpg";
import Dell from "../../../images/dell.jpg";

const Heading = tw.h1`text-lg md:text-2xl text-gray-100 pt-8 font-black text-center`;
const Container = tw.div`bg-primary-500`;

export default function App() {

  SwiperCore.use([Autoplay]);
  
  return (
    <>
      <Container>
        <Heading>Parters we work or have worked with</Heading>
        <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
            }}

            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              waitForTransition: true,            
            }}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
            initialSlide={2}
        >
        
            <SwiperSlide>
            <img src={Armis} />
            </SwiperSlide>
            <SwiperSlide>
            <img src={Amd} />
            </SwiperSlide>
            <SwiperSlide>
            <img src={Atos} />
            </SwiperSlide>
            <SwiperSlide>
            <img src={Barracuda} />
            </SwiperSlide>
            <SwiperSlide>
            <img src={Dell} />
            </SwiperSlide>
        </Swiper>
      </Container>
    </>
  );
}
