import React from "react";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "../../../misc/Headings";
import {ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import styled from "styled-components";
import FillerImage from "../../../images/filler-image.jpg"
import FillerImage1 from "../../../images/filler-image1.jpg"

const Component = tw.div``;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Subheading = tw(SubheadingBase)`text-center md:text-left font-bold`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
    tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const Image = styled.img(props => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`,
]);


export default({
    heading = "It’s all in the detail",
    subheading = "Our Fixed Daily Service Rate delivers the highest ROI",
    description = "It doesn’t matter how good your telesales team are if they can’t get to speak to the right contacts within an organisation. Zero conversations equal zero leads and with GDPR governance it is even more important to be sure of your data. ",
    heading1 = "Why use our marketing data?",
    subheading1 = "We Assure the quality of Leads and their success",
    description1 = "The mainstay of our business is an excellent quality database covering UK, EMEA, USA and Canada mid-market to Global 2000 companies, across multiple industry verticals. Our highly targeted data not only contains the more obvious contact and company information, but data relating to: Hardware Infrastructure, Software, Applications, Cloud Environment, Security, Virtualisation and so much more",
    heading2 = "Custom built data",
    subheading2 = "We Assure the quality of Leads and their success",
    description2 = "Our experienced Data Research teams build targeted custom databases to match your specific campaign requirements. We identify companies and contacts against target personas, industries, company size, and geographies. Leveraging professional networking, social media and company background sites, our databases have proven to out-perform the majority of IT industry data providers.",
    heading3 = "Database audit and cleansing",
    subheading3 = "We Assure the quality of Leads and their success",
    description3 = "Do you have your own database that needs updating and/or made compliant with current GDPR regulations? We offer a data audit and cleansing service to identify incorrect, missing and duplicate data, which is cleansed and updated to deliver a quality and compliant database.",
    imageRounded = true,
    imageBorder = false,
    imageShadow = false,
    imageCss = null,
    imageDecoratorBlob = false,
    imageDecoratorBlobCss = null,
    textOnLeft = true,

}) => {
    return(
        <>
            <Component>
                <TwoColumn>
                    <ImageColumn>
                        <Image css={imageCss} src={FillerImage} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
                        {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
                    </ImageColumn>
                    <TextColumn textOnLeft={textOnLeft}>
                        <TextContent>
                            {/* <Subheading>{subheading}</Subheading> */}
                            <Heading>{heading}</Heading>
                            <Description>{description}</Description>
                        </TextContent>
                    </TextColumn>
               </TwoColumn>
                <TwoColumn>
                    <ImageColumn>
                        <Image css={imageCss} src={FillerImage1} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
                        {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
                    </ImageColumn>
                    <TextColumn>
                        <TextContent>
                            {/* <Subheading>{subheading1}</Subheading> */}
                            <Heading>{heading1}</Heading>
                            <Description>{description1}</Description>
                        </TextContent>
                    </TextColumn>
               </TwoColumn>
                <TwoColumn>
                    <ImageColumn>
                        <Image css={imageCss} src={FillerImage} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
                        {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
                    </ImageColumn>
                    <TextColumn textOnLeft={textOnLeft}>
                        <TextContent>
                            {/* <Subheading>{subheading}</Subheading> */}
                            <Heading>{heading2}</Heading>
                            <Description>{description2}</Description>
                        </TextContent>
                    </TextColumn>
               </TwoColumn>
                <TwoColumn>
                    <ImageColumn>
                        <Image css={imageCss} src={FillerImage1} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
                        {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
                    </ImageColumn>
                    <TextColumn>
                        <TextContent>
                            {/* <Subheading>{subheading1}</Subheading> */}
                            <Heading>{heading3}</Heading>
                            <Description>{description3}</Description>
                        </TextContent>
                    </TextColumn>
               </TwoColumn>
                
            </Component>
        </>
    )
}