import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading } from "../../../misc/Headings";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Container = tw.div`md:pt-16 bg-primary-500 -mx-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto md:py-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const LeftColumn = tw(Column)`md:w-5/12 flex-shrink-0 mt-8`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-3xl sm:text-4xl lg:text-5xl text-center leading-tight text-gray-300`;
const SubHeading = tw(Subheading)`text-gray-300`
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`
const DescriptionChallenge = tw.p`text-center md:text-right text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4 mb-6 md:mb-8`
const DescriptionSolution = tw.p`text-center md:text-right text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`

const Statistics = tw.div`grid grid-rows-2 grid-flow-col-dense gap-x-8 mt-4`
const Statistic = tw.div`text-base sm:text-lg lg:text-xl p-4 lg:mt-10 text-center rounded shadow-xl text-gray-300 bg-primary-400`
const Key = tw.div`font-medium text-gray-300`

export default ({
    textOnLeft = false,
    statistics = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "Campaign length-weeks",
      value: "4",
    },
    {
      key: "Effective Conversations",
      value: "234",
    },
    {
      key: "Sales-Ready Opportunities",
      value: "24",
    },
  ];

  const [focus, setFocus] = React.useState(false);

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <Heading>The Campaign</Heading>
      <TwoColumn>
        <LeftColumn>
          <SubHeading>The Challenge</SubHeading>
          <DescriptionChallenge>MDF presents an excellent opportunity for partners to launch lead generation campaigns and reach new prospects. How could Barracuda build, scale, and execute a lead-gen project at very short notice, delivered within just one month?</DescriptionChallenge>
          <SubHeading>The Solution</SubHeading>
          <DescriptionSolution>Barracuda engaged Think Pipeline to create and drive a UK lead-gen campaign, with a $40,000 budget and a super-stretch pipeline revenue target of $400,000. Think Pipeline assigned a dedicated team to the project, and mined its extensive database to identify prospects that met both Barracuda’s and Microsoft’s objectives.</DescriptionSolution>
        </LeftColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <SubHeading>The Results</SubHeading>
            <Description>Within 4 weeks, Think Pipeline delivered 24-sales ready opportunities with a total pipeline value of $800,000, double Barracuda’s original super-stretch target. Based on those results, Barracuda has expanded its campaign to reach across EMEA, supported by MDF from an enthusiastic Microsoft.</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
              <Statistic key={index}>
                  <VisibilitySensor
                      onChange={isVisible => {
                        if (isVisible) {
                          setFocus(true);
                        } 
                      }}
                    >
                      <CountUp delay={2} start={focus ? 0 : null} end={statistic.value}>
                            {({ countUpRef }) => (
                              <div>
                          <span ref={countUpRef} />
                        </div>
                      )}
                      </CountUp>
                    </VisibilitySensor>
                <Key>{statistic.key}</Key>
              </Statistic>
              ))}
              <Statistic>
                <div>
                    $800.000
                </div>
                <Key>Total Pipeline Value</Key>
              </Statistic>
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
