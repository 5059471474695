import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading } from "../../../misc/Headings";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Container = tw.div`md:pt-16 bg-primary-500 -mx-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto md:py-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const LeftColumn = tw(Column)`md:w-5/12 flex-shrink-0 mt-8`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-3xl sm:text-4xl lg:text-5xl text-center leading-tight text-gray-300`;
const SubHeading = tw(Subheading)`text-gray-300`
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`
const DescriptionChallenge = tw.p`text-center md:text-right text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4 mb-6 md:mb-8`
const DescriptionSolution = tw.p`text-center md:text-right text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`

const Statistics = tw.div`grid grid-rows-2 grid-flow-col-dense gap-x-8 mt-4`
const Statistic = tw.div`text-base sm:text-lg lg:text-xl p-4 lg:mt-10 text-center rounded shadow-xl text-gray-300 bg-primary-400`
const Key = tw.div`font-medium text-gray-300`

export default ({
    textOnLeft = false,
    statistics = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "Campaign length-weeks",
      value: "4",
    },
    {
      key: "Effective Conversations",
      value: "234",
    },
    {
      key: "Sales-Ready Opportunities",
      value: "24",
    },
  ];

  const [focus, setFocus] = React.useState(false);

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <Heading>The Campaign</Heading>
      <TwoColumn>
        <LeftColumn>
          <SubHeading>The Challenge</SubHeading>
          <DescriptionChallenge>Cristie Data relied on events such as IPExpo (now known as DTX) to generate sales pipeline with a good, measurable track record of return on investment. When Covid-19 lockdowns impacted the economy and events were off the menu, how could Cristie Data reach out to new prospects to drive the business forwards?</DescriptionChallenge>
          <SubHeading>The Solution</SubHeading>
          <DescriptionSolution>Cristie Data looked for a telemarketing agency partner that could source and reach out to target prospects, and deliver validated opportunities to the sales team. Having worked with other agencies on similar marketing projects in the past, including Think Pipeline, Cristie Data once again chose Think Pipeline to support its lead generation campaigns during the Covid 19 crisis.</DescriptionSolution>
        </LeftColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <SubHeading>The Results</SubHeading>
            <Description>Over six months, Think Pipeline generated confirmed sales opportunities valued at around     £1 million in potential revenue, a 30x return on investment. In the process, Cristie Data has discovered how outbound telemarketing adds an extra dimension to its pipeline.  Even as “in-person” events return, the company will retain Think Pipeline to discover new sales  opportunities.</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
              <Statistic key={index}>
                  <VisibilitySensor
                      onChange={isVisible => {
                        if (isVisible) {
                          setFocus(true);
                        } 
                      }}
                    >
                      <CountUp delay={2} start={focus ? 0 : null} end={statistic.value}>
                            {({ countUpRef }) => (
                            <div>
                                 <span ref={countUpRef} />
                            </div>
                      )}
                      </CountUp>
                    </VisibilitySensor>
                <Key>{statistic.key}</Key>
              </Statistic>
              ))}
              <Statistic>
                <div>
                    $1.000.000
                </div>
                <Key>Total Pipeline Value</Key>
              </Statistic>
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
