import React from "react";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "../../../misc/Headings";
import {ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import styled from "styled-components";
import FillerImage from "../../../images/filler-image.jpg"
import FillerImage1 from "../../../images/filler-image1.jpg"





const Component = tw.div``;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Subheading = tw(SubheadingBase)`text-center md:text-left font-bold`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
    tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const Image = styled.img(props => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`,
]);


export default({
    heading = "Return on Investment",
    subheading = "Our Fixed Daily Service Rate delivers the highest ROI",
    description = "Think Pipeline’s Daily Service Rate (DSR) methodology delivers higher ROI than the traditional Pay Per Appointment (PPA). Unlike PPA, which only delivers generated leads, ignoring any pipeline opportunities, DSR delivers leads, AND qualified pipeline. The additional pipeline includes nurtures and all conversation intellect, providing our customers with leads AND a qualified pipeline.",
    heading1 = "Guaranteed Results",
    subheading1 = "We Assure the quality of Leads and their success",
    description1 = "Our experienced campaign management team works with you to scope and agree campaign goals and objectives, which forms the basis of a mutually agreed SLA.  As part of the process, we identify the right personas to target and cross match with our extensive in-house industry database.  We determine a realistic timeframe, taking in to account industry vertical and seasonal “quiet periods”, ensuring the most efficient dialling utilisation to deliver leads. It is this proven methodology that enables Think Pipeline to guarantee results.",
    imageRounded = true,
    imageBorder = false,
    imageShadow = false,
    imageCss = null,
    imageDecoratorBlob = false,
    imageDecoratorBlobCss = null,
    textOnLeft = true,

}) => {
    return(
        <>
            <Component>
                <TwoColumn>
                    <ImageColumn>
                        <Image css={imageCss} src={FillerImage} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
                        {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
                    </ImageColumn>
                    <TextColumn textOnLeft={textOnLeft}>
                        <TextContent>
                            <Subheading>{subheading}</Subheading>
                            <Heading>{heading}</Heading>
                            <Description>{description}</Description>
                        </TextContent>
                    </TextColumn>
               </TwoColumn>
                <TwoColumn>
                    <ImageColumn>
                        <Image css={imageCss} src={FillerImage1} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
                        {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
                    </ImageColumn>
                    <TextColumn>
                        <TextContent>
                            <Subheading>{subheading1}</Subheading>
                            <Heading>{heading1}</Heading>
                            <Description>{description1}</Description>
                        </TextContent>
                    </TextColumn>
               </TwoColumn>
            </Component>
        </>
    )
}