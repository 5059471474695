import ServicesHero from "./services/ServicesHero"
import ServicesDisplay from "./services/ServicesDisplay"
import Footer from "../helpers/Footer"

export default () => {
    return(
        <>
            <ServicesHero />
            <ServicesDisplay />
            <Footer />
        </>
    )
}