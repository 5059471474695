import React, { Component } from "react"
import { Map, GoogleApiWrapper } from "google-maps-react"


class MapContainer extends Component {
    render() {
    return(
        <Map 
                        google = {this.props.google}
                        style = {{width: "50%", height:"50%", alignItems:"flex-start", }}
                        zoom = {15}
                        initialCenter = {
                            {
                                lat: 51.320690,
                                lng: -0.557040
                            }
                        }
                    />
    )
}
}

export default GoogleApiWrapper({
    apiKey:"AIzaSyApDe5pxWnz-PdfDLh-7H49PkEEQX6rpsE"
})(MapContainer)