import Footer from "helpers/Footer";
import React from "react";
import ServicesChannelDevelopmentHero from "./services/ServicesComponents/ServicesChannelDevelopmentHero";
import ServicesChannelDevelopmentInfo from "./services/ServicesComponents/ServicesChannelDevelopmentInfo";
import ServicesChannelDevelopmentSteps from "./services/ServicesComponents/ServicesChannelDevelopmentSteps";
import ServicesLeadGenerationSwiper from "./services/ServicesComponents/ServicesLeadGenerationSwiper";


export default() => {
    return(
        <>
            <ServicesChannelDevelopmentHero />
            <ServicesLeadGenerationSwiper />
            <ServicesChannelDevelopmentSteps />
            <ServicesChannelDevelopmentInfo />
            <Footer />
        </>
    )
}
